.fatade-container {
  margin-left: 130px;
  margin-right: 130px;
  margin-top: 30px;
  margin-bottom: 30px;
  
  .title {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
  }
  .title.mobile {
    display: none;
  }
  .sub-title {
    font-size: 18px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 16px;
    text-align: center;
    padding-left: 60px;
    padding-right: 60px;
  }
  .sub-title.m-0 {
    margin-bottom: 3px;
  }
  .sub-title.m-12 {
    margin-bottom: 12px;
  }
  .sub-title.mobile {
    display: none;
  }
  .description {
    font-size: 24px;
    text-align: center;
  }
  .description.about {
    padding-left: 100px;
    padding-right: 100px;
  }
  .slider-section {
    .slider-photos {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
  
      .swiper-wrapper-slider {
        width: 80%;
      }
    }
  }
}

a {
  color: unset;
  cursor: pointer;
  text-decoration: unset;
}

html.scrollstop {
  overflow: hidden;
}

.swiper-button-next, .swiper-button-prev {
  width: 60px;
  height: 60px;
}
.swiper-button-next::after {
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  background-image: url("../assets/next-arrow.png");
  background-size: cover;
  position: absolute;
}

.swiper-button-prev::after {
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  background-image: url("../assets/prev-arrow.png");
  background-size: cover;
  position: absolute;
}

@media (max-width: 768px) {
  .fatade-container {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 24px;
    margin-bottom: 24px;

    .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .title.desktop {
      display: none;
    }
    .title.mobile {
      display: block;
    }
    .sub-title.desktop {
      display: none;
    }
    .sub-title.mobile {
      display: block;
    }
    .sub-title {
      font-size: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .description {
      font-size: 14px;
      text-align: center;
    }
    .description.about {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
  .swiper-button-next::after {
    width: 30px;
    height: 30px;
    right: -5px;
  }
  
  .swiper-button-prev::after {
    width: 30px;
    height: 30px;
    left: -5px;
  }
}
