.header {
  height: 80px;
  display: flex;
  gap: 40px;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  .menu-btn {
    display: none;
  }
  .logo {
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
  }
  .sub-title-mobile {
    display: none;
  }
  .menus {
    display: flex;
    justify-content: space-between;
    width: 60%;
    
    .menu-item {
      cursor: pointer;
      padding-left: 20px;
      padding-right: 20px;
      opacity: 0.4;
      transition: 0.5s;

      p {
        font-size: 20px;
        font-weight: 500;
        position: relative;
      }
      p::after {
        position: absolute;
        content: "";
        background: black;
        bottom: 0;
        right: 0;
        height: 2px;
        width: 0%;
        transition: 300ms ease-in-out;
      }
    }
    a.active {
      .menu-item {
        opacity: 1;
        p::after {
          width: 100%;
          left: 0;
        }
      }
    }
    .menu-item:hover {
      opacity: 1;
      p::after {
        width: 100%;
        left: 0;
      }
    }
  }
}

.slide-to-right {
  animation-name: slide-animate;
  animation-duration: 0.8s;
}
.slide-to-right.slide-out {
  animation-name: slide-animate-out;
  animation-duration: 0.8s;
}
@keyframes slide-animate {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

@keyframes slide-animate-out {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

@media (max-width: 768px) {
  .header {
    padding-left: 12px;
    padding-right: 12px;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding-bottom: 10px;
    z-index: 999;

    .menu-btn {
      display: block;
      position: absolute;
      padding: 6px;
      left: 8px;
      top: 45%;
      -webkit-transform: translate(0,-50%);
      transform: translate(0,-50%);    
      max-width: 27px;
      img {
        width: 100%;
      }
    }
    .logo {
      padding: 0;
      width: 65%;
      img {
        width: 100%;
      }
    }
    .menus {
      display: none;
    }
    .sub-title-mobile {
      display: block;
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translate(-50%, 0);
      
      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 99;
  }
  #ModalMenu {
    position: fixed;
    background-color: #fff;

    .close-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 16px;
      right: 16px;

      img {
        width: 100%;
      }
    }

    .modal-content {
      width: 100vw;
      height: 100vh;

      .modal-body {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
  
        .menus-mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          
          a {
            width: 100%;
  
            .menu-item {
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              p {
                font-size: 18px;
                font-weight: 500;
                opacity: 0.4;
                position: relative;
              }
              p::after {
                position: absolute;
                content: "";
                background: black;
                bottom: 0;
                right: 0;
                height: 2px;
                width: 0%;
              }
            }
          }
          a.active {
            .menu-item {
              p {
                opacity: 1;
              }
              p::after {
                width: 100%;
              }
            }
          }
        }
      }
    }
    
    
  }
}