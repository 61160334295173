.home {
  display: flex;
  gap: 20px;
  justify-content: center;

  .column-home {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .page-item {
      position: relative;
      cursor: pointer;
      width: 100%;

      img {
        width: 100%;
        vertical-align: middle;
      }
      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0;
        transition: 0.2s;
      }
      .item-label {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 24px;
          color: #fff;
          font-weight: 600;
          text-align: center;
        }
      }
    }
    .page-item:hover {
      .overlay {
        opacity: 0.6;
      }
    }
  }
  .column-home.tall {
    max-width: 380px;
  }
  .column-home.wide {
    max-width: 512px;
  }
}

.home.desktop.personal {
  .column-home.tall {
    max-width: 350px;
    width: 23%;
  }
}

.home.desktop.collab {
  .column-home.tall {
    max-width: 600px;
  }
  .column-home.wide {
    max-width: 770px;
  }
}

.home.mobile {
  display: none;
}

@media (max-width: 768px) {
  .home.desktop {
    display: none;
  }
  .home.mobile {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    gap: 9px;

    .column-home {
      gap: 9px;

      .page-item {
        position: relative;
        cursor: pointer;
  
        .item-label {
          p {
            font-size: 12px;
            font-weight: 700;
            text-shadow: 0px 0px 15px black;
          }
        }
      }
    }
    .column-home.tall {
      max-width: 42%;
    }
    .column-home.wide {
      max-width: 55%;
    }
  }
  .home.mobile.personal {
    .column-home.tall {
      max-width: 50%;
      width: 50%;
    }
  }
  .home.mobile.collab {
    justify-content: center;
    .column-home.wide {
      max-width: 80%;
    }
  }
}